<template>
  <div>
    <b-card img-top no-body>
      <div class="contain-preview">
          <b-embed type="iframe" aspect="16by9" :src="`https://www.youtube.com/embed/${media.id}?rel=0`" allowfullscreen></b-embed>
      </div>
      <b-card-body>
        <b-card-text>
          <small class="text-muted">{{ dateAdd}}</small>
        </b-card-text>
        <b-card-text
          >{{ media.title }}
          <small class="text-muted">({{ videoDuration }})</small></b-card-text
        >
        <b-card-text v-if="media.metrics.ltd_rate">
          <small class="text-muted">
            {{$t('youtube.vidRat')}}: {{ media.metrics.ltd_rate.value }}
            {{ media.metrics.ltd_rate.mark }}</small
          >
        </b-card-text>
        <b-card-text>
          <small class="text-muted">
            {{$t('youtube.audienceAtt')}}: {{ audienceActitude }} {{$t('youtube.likeDislike')}}.</small
          >
        </b-card-text>
      </b-card-body>
      <b-card-footer class="p-1">
        <b-row  class="justify-content-around align-items-center">
          <b-col cols="6">
          </b-col>
          <b-col cols="12">
            <bar-metrics :likes="likes" :comments="comments" :views="views" :dislikes="dislikes"></bar-metrics>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardFooter,
  BCardText,
  BRow,
  BCol,
  VBModal,
  BEmbed,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import BarMetrics from '@/views/components/media/BarMetrics'
import { getFormat } from '@/libs/utils/formats';
export default {
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BCardText,
    BRow,
    BCol,
    BEmbed,
    BarMetrics,
  },
  directives: {
    "b-modal": VBModal,
    Ripple
  },
  props: {
    media: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      getFormat,
      imageMedia: require('@/assets/images/dashboard/b1b1b1.png'),
      dateTime: 'es-MX'
    }
  },
  computed: {
    audienceActitude() {
      const val =
        this.media.metrics.likes_count.value /
        this.media.metrics.dislikes_count.value;
      return Math.round(val);
    },
    videoDuration() {
      return `${Math.round(this.media.metrics.length_sec.value / 60)} min.`;
    },
    likes() {
      return getFormat(this.media.metrics.likes_count.value)
    },
    comments() {
      return getFormat(this.media.metrics.comments_count.value)
    },
    views() {
      return getFormat(this.media.metrics.views_count.value)
    },
    dislikes() {
      return getFormat(this.media.metrics.dislikes_count.value)
    },
    dateAdd() {
      if (this.media.time_added === false) return ''
      const date = new Date(this.media.time_added * 1000)
      return date.toLocaleDateString(this.dateTime, {year: 'numeric', month: 'long', day: 'numeric'})
    }
  },
  methods: {
  },
  watch: {
    '$i18n.locale'(date) {
      if (date === 'en') {
        this.dateTime = 'en-US' 
      } else {
        this.dateTime = 'es-MX' 
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.contain-preview {
  position: relative;
  width: 100%;
  img {
    width: 100%;
  }
  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  .btn:hover {
    background-color: black;
  }
}
</style>
