<template>
  <b-row class="justify-content-around align-items-center">
    <b-col sm="6" md="3" v-if="views">
      <div class="truncate d-flex justify-content-around">
        <h5 class="mb-25 font-weight-bolder">
          <feather-icon size="18" :icon="'EyeIcon'" />
        </h5>
        <span>{{ views }}</span>
      </div>
    </b-col>
    <b-col sm="6" md="3" v-if="comments">
      <div class="truncate d-flex justify-content-around">
        <h5 class="mb-25 font-weight-bolder">
          <feather-icon size="18" :icon="'MessageSquareIcon'" />
        </h5>
        <span>{{ comments }}</span>
      </div>
    </b-col>
    <b-col sm="6" md="3" v-if="likes">
      <div class="truncate d-flex justify-content-around">
        <h5 class="mb-25 font-weight-bolder">
          <feather-icon size="18" :icon="'ThumbsUpIcon'" />
        </h5>
        <span>{{ likes }}</span>
      </div>
    </b-col>
    <b-col sm="6" md="3" v-if="dislikes">
      <div class="truncate d-flex justify-content-around">
        <h5 class="mb-25 font-weight-bolder">
          <feather-icon size="18" :icon="'ThumbsDownIcon'" />
        </h5>
        <span>{{ dislikes }}</span>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol
  },
  props: {
    likes: {
      type: String,
      required: false
    },
    comments: {
      type: String,
      required: false
    },
    views: {
      type: String,
      required: false
    },
    dislikes: {
      type: String,
      required: false
    },
  }
};
</script>
